import * as sync from '../../../src/syncWorker'

import { ANIMAL_CREATED_AFTER_D0, ANIMAL_EDITED_AFTER_D0 } from '../../utils/constants'
import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import Autocomplete, {
  autocompleteDefaultOnChangeSavingID,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteDomainValueOptionLabelSavingIDUsingDescription,
  autocompleteOptionLabelUsingCustomFieldSavingID,
  autocompleteDefaultOnChange,
} from '../../components/material/Autocomplete'
import Button, { COLORS } from '../../components/material/Button'
import {
  FAZENDA,
  LOTE,
  REQUIRED_FIELD_MESSAGES,
  RETIRO,
  formatName,
  isDateNullOrValid,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator,
} from '../../utils/formHelper'
import React, { Component, Fragment } from 'react'
import ResponsiveTable, { CELL_TYPES } from './../../components/ResponsiveTable'
import TextField, {
  textfieldDefaultOnChange,
} from '../../components/material/TextField'

import { Container } from '@material-ui/core'
import CustomSwitch from '../../components/CustomSwitch'
import DateField from '../../components/material/DateField'
import DeleteIcon from '@material-ui/icons/Delete'
import Divider from '@material-ui/core/Divider'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import { LocalStorageHelper } from '../../utils/localStorageHelper'
import MainContainer from '../../components/MainContainer'
import MicIcon from '@material-ui/icons/Mic'
import Prompt from '../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import SaveIcon from '@material-ui/icons/Save'
import SpeechRecognition from '../../components/SpeechRecognition'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import _ from 'lodash'
import { duplicatePage } from '../../utils/notesHelper'
import { eccSpeechRecognition } from '../../utils/eccSpeechRecognition'
import { getLoggedUser } from '../../redux/auth/actions'
import moment from 'moment'
import track from 'react-tracking'
import { verify } from '../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'
import { sortList } from '../../utils/helper'

@track(() => ({ page: 'D0', date: new Date() }))
class D0 extends Component {
  constructor(props) {
    super(props)
    let sortedCows = sortList(props.vacas, 'codVaca')

    this.state = {
      ...props.initialValues,
      count: this.props.vacas.length,
      showingFinalizeConfirmation: false,
      batchesIds: props.batches.map((batch) => batch.id),
      loading: false,
      allCows: sortedCows,
      searchError: false,
      checked: true,
      toEditCow: false,
      cowEdited: null,
      confirmedBackupDownload: true,
      showingNoBackupConfirmation: false,
      selectedCows: sortedCows,
      deleteConfirmationOpened: false,
      selectedCow: null,
      dataPartoIndividual: null,
      clD0: this.props.clD0.filter((x) => x.valor === 'Não Avaliado')[0].id,
      enterSubmit: false,
      focusDate: false,
      lastSaveOrEditCowId: '',
      filtersVisible: false,
      filters: {
        ordemMatrizFilter: null,
        numUsoDispFilter: null,
        racaMatrizFilter: null
      }
    }

    this.codVaca = React.createRef()
    this.codLeitorBastao = React.createRef()

    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.autocompleteDefaultOnChangeSavingID =
      autocompleteDefaultOnChangeSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingID =
      autocompleteDomainValueOptionLabelSavingID.bind(this)
    this.autocompleteOptionLabelUsingCustomFieldSavingID =
      autocompleteOptionLabelUsingCustomFieldSavingID.bind(this)
    this.autocompleteDomainValueOptionLabelSavingIDUsingDescription =
      autocompleteDomainValueOptionLabelSavingIDUsingDescription.bind(this)
    this.autocompleteDefaultOnChange = autocompleteDefaultOnChange.bind(this)

    this.onSave = this.onSave.bind(this)
    this.editCow = this.editCow.bind(this)
    this.onClean = this.onClean.bind(this)
    this.fullClean = this.fullClean.bind(this)
    this.deleteCow = this.deleteCow.bind(this)
    this.finalize = this.finalize.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSave = this.handleSave.bind(this)

    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.renderTitle = this.renderTitle.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)
  }

  @track((props, state, cow) => ({ action: 'Deletou uma Matriz', value: cow[0] }))
  async deleteCow(selectedCow) {
    if (selectedCow === null) return

    const repository = new Repository(AVAILABLE_ENTITIES.D0S)
    const request = await repository.delete(selectedCow)

    const newState = {
      deleteConfirmationOpened: false,
      selectedCow: null,
      selectedCowCode: null,
    }

    if (request.success) {
      console.log(`Delete of cow ${selectedCow} successful`)
      newState.allCows = this.state.selectedCows.filter(
        (x) => x.id !== selectedCow
      )
      newState.selectedCows = this.state.selectedCows.filter(
        (x) => x.id !== selectedCow
      )
      this.setState({
        count: this.state.count - 1,
      })
    } else {
      console.log(
        `Delete of cow ${selectedCow} failed: ${request.exception}`
      )
    }

    this.setState(newState)
  }

  @track((props, state) => ({ action: 'Editou uma matriz', value: state.lastSaveOrEditCowId }))
  async editCow() {
    let errorInputRequired = false
    const cowSelected = this.state.cowEdited

    try {
      this.setState({ loading: true })
      let payload = {
        ...cowSelected,
        ..._.pick(this.state, [
          'codVaca',
          'codLeitorBastao',
          'observacaoD0',
          'ecc',
          'idade_Nov_Pri',
          'responsavelId',
          'dispositivoIntravaginal',
          'dataPartoIndividual',
          'numero_Uso_Disp',
          'racaMatriz',
          'ordemMatriz',
          'clD0',
        ]),
      }

      payload.peso_EM = parseInt(this.state.peso_EM) || null
      payload.loteId = this.props.batch.id
      payload.dataProcesso = moment.utc()
      payload.omit_from_dg_final = false
      payload.iatf = 0

      if (this.props.batch.dias !== '') {
        payload.observacaoDG = ANIMAL_EDITED_AFTER_D0
      }
      let newAllCows = this.state.allCows
      let allCowsIds = []

      newAllCows.map((x) => allCowsIds.push(x.id))
      let pos = allCowsIds.indexOf(cowSelected.id)

      newAllCows.splice(pos, 1)

      let requiredFieldsState = this.requiredFieldsEvaluator()
      let validDates = this.isDateNullOrValid(this.state.dataPartoIndividual)

      if (
        (requiredFieldsState !== null || !validDates) &&
        !payload.codLeitorBastao
      ) {
        this.setState(requiredFieldsState)
        errorInputRequired = true
      } else {
        errorInputRequired = false

        if (!payload.codVaca) payload.codVaca = payload.codLeitorBastao

        this.setState({ requiredFields: false, codVaca_error: null })
        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.update(payload)

        if (createReq.success) {
          console.log('D0 updated successfully')

          newAllCows.push(createReq.response)

          sortList(newAllCows, 'codVaca')

          this.onClean()
          this.setState({
            toEditCow: false,
            allCows: newAllCows,
            focusDate: false,
            dataPartoIndividual: null,
            lastSaveOrEditCowId: createReq.response.id
          })
        } else {
          console.log(
            `There was an error trying to update D0: ${createReq.exception}`
          )
        }
      }
    } catch (exception) {
      console.log(`There was an error trying to update D0: ${exception}`)
    } finally {
      this.setState({
        loading: false,
        searchError: false,
      })
      if (!errorInputRequired) {
        this.onClean()
        this.codVaca.current.focus()
      }
    }
  }

  @track((props, state) => ({ action: 'Criou uma matriz', value: state.lastSaveOrEditCowId }))
  async onSave() {
    let errorInputRequired = false

    let payload = {}
    try {
      this.setState({ loading: true })
      payload = {
        ..._.pick(this.state, [
          'codVaca',
          'codLeitorBastao',
          'observacaoD0',
          'ecc',
          'idade_Nov_Pri',
          'responsavelId',
          'dispositivoIntravaginal',
          'dataPartoIndividual',
          'numero_Uso_Disp',
          'racaMatriz',
          'ordemMatriz',
          'clD0',
        ]),
      }
      payload.peso_EM = parseInt(this.state.peso_EM)
      payload.loteId = this.props.batch.id
      payload.dataProcesso = moment.utc()
      payload.omit_from_dg_final = false
      payload.iatf = 0

      if (this.props.batch.dias !== '') {
        payload.observacaoDG = ANIMAL_CREATED_AFTER_D0
      }

      let requiredFieldsState = this.requiredFieldsEvaluator()
      let validDates = this.isDateNullOrValid(this.state.dataPartoIndividual)

      if (
        (requiredFieldsState !== null || !validDates) &&
        !payload.codLeitorBastao
      ) {
        this.setState(requiredFieldsState)
        errorInputRequired = true
      } else {
        errorInputRequired = false

        if (!payload.codVaca) payload.codVaca = payload.codLeitorBastao

        this.setState({ codVaca_error: null })
        const repository = new Repository(AVAILABLE_ENTITIES.D0S)
        const createReq = await repository.post(payload)

        if (createReq.success) {
          console.log('D0 registered successfully')
          this.setState({
            count: this.state.count + 1,
            focusDate: false,
            dataPartoIndividual: null,
            lastSaveOrEditCowId: createReq.response.id
          })
          const newState = {}
          this.state.selectedCows.unshift(createReq.response)
          newState.selectedCows = this.state.selectedCows


          this.setState(sortList(newState.selectedCows, 'codVaca'))

          if (this.props.batch.dias !== '') {
            let iatfPayload = {
              vacaId: createReq.response.id,
              loteId: this.state.allCows[0].loteId,
              observacao: ANIMAL_CREATED_AFTER_D0,
            }
            const iatfRepository = new Repository(AVAILABLE_ENTITIES.IATFS)
            const createRequest = await iatfRepository.post(iatfPayload)
            if (!createRequest.success) {
              console.log(
                `There was a problem trying to create entry for cow ${payload.id}: ${createRequest.exception}`
              )
            }
          }
        } else {
          console.log(
            `There was an error trying to create D0: ${createReq.exception}`
          )
        }
      }
    } catch (exception) {
      console.log(`There was an error trying to create D0: ${exception}`)
    } finally {
      this.setState({
        loading: false,
        searchError: false,
        toEditCow: false,
      })
      if (!errorInputRequired) {
        this.onClean()

        if (
          payload.codLeitorBastao &&
          payload.codVaca === payload.codLeitorBastao
        ) {
          this.codLeitorBastao.current.focus()
        } else {
          this.codVaca.current.focus()
        }
      }
    }
  }

  handleSearch(e) {
    const val = e.target.value

    if (_.isEmpty(val)) {
      this.setState({ selectedCows: this.state.allCows })
    } else {
      const filtedCodVaca = this.state.allCows.filter(
        (x) => x.codVaca?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      const filtedCodLeitorBastao = this.state.allCows.filter(
        (x) => x.codLeitorBastao?.toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      const filtedAllData = filtedCodVaca.concat(filtedCodLeitorBastao)

      const selectedCows = [...new Set(filtedAllData)]

      this.setState({ selectedCows })
    }
  }

  handleSwitchChange = (event) => {
    this.setState({ checked: event.target.checked })
  }

  handleToggleFilters = () => {
    this.setState({ filtersVisible: !this.state.filtersVisible });
    this.cleanFilters();
  };

  applyFilters() {
    const { racaMatrizFilter, ordemMatrizFilter, numUsoDispFilter } = this.state.filters;
    let selectedCows = this.state.allCows;

    // Filtrar por Raça
    if (!_.isEmpty(racaMatrizFilter)) {
      selectedCows = selectedCows.filter(
        (data) => data.racaMatriz === racaMatrizFilter.id
      )
    }

    // Filtrar por Ordem Matriz
    if (!_.isEmpty(ordemMatrizFilter)) {
      selectedCows = selectedCows.filter(
        (data) => data.estado === ordemMatrizFilter.id
      );
    }

    // Filtrar pela Num do Dispositivo
    if (!_.isEmpty(numUsoDispFilter)) {
      selectedCows = selectedCows.filter(
        (data) => data.numero_Uso_Disp === numUsoDispFilter.id
      );
    }

    this.setState({ selectedCows });
  }


  cleanFilters() {
    this.setState({
      filters: {
        ordemMatrizFilter: null,
        numUsoDispFilter: null,
        racaMatrizFilter: null
      }
    }, this.applyFilters);
  }

  async handleSave() {
    if (this.state.checked && this.state.codVaca != null) {
      const val = this.state.codVaca
      let searchError = null
      if (this.state.selectedCows.map((m) => m.codVaca).indexOf(val) >= 0) {
        this.setState({
          searchError:
            'Já existe uma matriz com esse nome cadastrada nesse lote.',
        })
        return
      } else {
        const outOfBatch = await utils.farmWideSearch(
          val,
          this.state.batchesIds,
          this.props.farm.id
        )

        if (outOfBatch.msg) {
          searchError = outOfBatch.msg
          if (searchError === 'Matriz não encontrada.') {
            this.onSave()
            return
          }
          if (searchError.indexOf('não faz parte deste Lote') >= 0) {
            searchError = searchError.replace(
              `A matriz ${this.state.codVaca} não faz parte deste Lote.`,
              ``
            )
          }
          this.setState({
            searchError,
          })
        } else {
          this.onSave()
        }
      }
    } else {
      this.onSave()
    }
  }

  onClean() {
    this.setState({
      codVaca: null,
      codLeitorBastao: null,
      observacaoD0: null,
      peso_EM: null,
      clD0: this.props.clD0.filter((x) => x.valor === 'Não Avaliado')[0].id,
      ..._.pick(this.props.initialValues, [
        'idade_Nov_Pri',
        'responsavelId',
        'dataPartoIndividual',
        'racaMatriz',
        'ordemMatriz',
      ]),
    })
  }

  onEnterPress = (e) => {
    if (this.props.user.roles[0].name !== 'Cliente (somente visualização)') {
      if (
        e.key === 'Enter' &&
        e.shiftKey === false &&
        this.state.enterSubmit === false
      ) {
        e.preventDefault()

        this.setState({ enterSubmit: true })
      }

      if (
        e.key === 'Enter' &&
        e.shiftKey === false &&
        this.state.enterSubmit &&
        !this.state.toEditCow
      ) {
        e.preventDefault()

        return this.handleSave()
      }

      if (
        e.key === 'Enter' &&
        e.shiftKey === false &&
        this.state.enterSubmit &&
        this.state.toEditCow
      ) {
        e.preventDefault()

        return this.editCow()
        // return
      }
    }
  }

  fullClean() {
    this.setState({
      codVaca: null,
      codLeitorBastao: null,
      observacaoD0: null,
      peso_EM: null,
      ecc: null,
      idade_Nov_Pri: null,
      responsavelId: null,
      dispositivoIntravaginal: null,
      dataPartoIndividual: null,
      numero_Uso_Disp: null,
      racaMatriz: null,
      ordemMatriz: null,
      clD0: null,
    })
  }

  @track({ action: 'Finalizou o D0' })
  async finalize(downloadBackup) {
    if (this.props.batch.dias === '') {
      const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)
      const updateReq = await repository.update({
        id: this.props.batch.id,
        dias: this.props.d0.id,
      })
      if (updateReq.success) {
        console.log('D0 successfully finished')
        if (this.props.farm.retiro) {
          LocalStorageHelper.add('reportExportWarning', true)
          this.props.history.push(
            `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
          )
        } else {
          this.props.history.push(`/farms/${this.props.farm.id}`)
        }
      } else {
        console.log(
          `There was an error trying to finish D0: ${updateReq.exception}`
        )
      }
    } else {
      if (this.props.farm.retiro) {
        LocalStorageHelper.add('reportExportWarning', true)
        this.props.history.push(
          `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`
        )
      } else {
        this.props.history.push(`/farms/${this.props.farm.id}`)
      }
    }

    sync.downloadProofOfManagement({
      values: this.props.batch.dias === '' ? ['lotes', 'd0s'] : ['d0s', 'iatfs'],
      text: 'Comprovante de manejo D0 - ' +
        formatName(this.props.batch.nomeLote, LOTE) +
        ' - ' +
        formatName(this.props.farm.nome, FAZENDA) +
        ' - ' +
        moment.utc().valueOf() +
        '.progerar',
      batchIds: this.props.match.params.id.split(';')
    })
  }

  renderTitle() {
    return (
      <Fragment>
        Protocolo: {this.props.protocol.name}
        <br />
        Manejo: D0
      </Fragment>
    )
  }

  renderColumnLabel(row, rowItem, prop, label) {
    return (
      this.props[prop] &&
      row[rowItem] &&
      this.props[prop].find((x) => x.id === row[rowItem])[label]
    )
  }

  getTableColumnData() {
    return [
      {
        name: 'Matriz (ID)',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        grid: 2,
        label: (row) => row.codVaca,
      },
      {
        name: 'Raça',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: (row) =>
          this.renderColumnLabel(row, 'racaMatriz', 'racaMatriz', 'descricao'),
      },
      {
        name: 'Ordem',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'ordemMatriz',
            'ordemMatriz',
            'descricao'
          ),
      },
      {
        name: 'ECC',
        type: CELL_TYPES.TEXT,
        label: (row) => this.renderColumnLabel(row, 'ecc', 'ecc', 'valor'),
      },
      {
        name: 'Peso',
        type: CELL_TYPES.TEXT,
        sortBy: true,
        label: (row) => row.peso_EM,
      },
      {
        name: 'Dispositivo',
        type: CELL_TYPES.TEXT,
        grid: 2,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'dispositivoIntravaginal',
            'implantes',
            'valor'
          ),
      },
      {
        name: 'Uso',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(
            row,
            'numero_Uso_Disp',
            'usoImplantes',
            'valor'
          ),
      },
      {
        name: 'Idade',
        type: CELL_TYPES.TEXT,
        label: (row) =>
          this.renderColumnLabel(row, 'idade_Nov_Pri', 'idadeMatriz', 'valor'),
      },
      {
        name: 'CL',
        type: CELL_TYPES.TEXT,
        label: (row) => this.renderColumnLabel(row, 'clD0', 'clD0', 'valor'),
      },
      {
        type: CELL_TYPES.BUTTON_ARR,
        buttons: (row) =>
          _.compact([
            {
              icon: <EditIcon />,
              label: 'Editar',
              onClick: () => {
                this.codVaca.current.focus()
                this.setState({
                  cowEdited: row,
                  toEditCow: true,
                  focusDate: row?.dataPartoIndividual ? true : false,
                  selectedCow: row?.id,
                  selectedCowCode: row?.codVaca,
                  codVaca:
                    row.codVaca === row?.codLeitorBastao ? null : row?.codVaca,
                  codLeitorBastao: row?.codLeitorBastao,
                  ecc: row?.ecc,
                  peso_EM: row?.peso_EM,
                  clD0: row?.clD0,
                  observacaoD0: row?.observacaoD0,
                  idade_Nov_Pri: row?.idade_Nov_Pri,
                  racaMatriz: row?.racaMatriz,
                  ordemMatriz: row?.ordemMatriz,
                  dispositivoIntravaginal: row?.dispositivoIntravaginal,
                  dataPartoIndividual: row?.dataPartoIndividual,
                  numero_Uso_Disp: row?.numero_Uso_Disp,
                  responsavelId: row?.responsavelId,
                })
              },
            },
            {
              icon: <DeleteIcon />,
              label: 'Excluir',
              onClick: () =>
                this.setState({
                  deleteConfirmationOpened: true,
                  selectedCow: row.id,
                  selectedCowCode: row.codVaca,
                }),
            },
          ]),
      },
    ]
  }

  getButtons() {
    return [
      {
        onClick: () => this.setState({ showingFinalizeConfirmation: true }),
        icon: <DoneIcon />,
        label: 'Finalizar',
        disabled: this.props.user.roles[0].name === 'Cliente (somente visualização)'
      },
    ]
  }

  getPaths() {
    const extraPath = this.props.farm.retiro && {
      route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}`,
      label: formatName(this.props.corral.nome, RETIRO),
    }

    return _.compact([
      {
        route: `/farms/${this.props.farm.id}`,
        label: formatName(this.props.farm.nome, FAZENDA),
      },
      extraPath,
      {
        route: `/farms/${this.props.farm.id}/corrals/${this.props.corral.id}/batches/${this.props.batch.id}`,
        label: formatName(this.props.batch.nomeLote, LOTE),
      },
      {
        label: 'D0',
      },
    ])
  }

  renderHeader() {
    return (
      <>
        <div className='grid-item p-12 t-6 d0-grid-form-top-info-left'>
          <h3>Manejos e Produtos</h3>
          <ul>
            <li>
              <strong>Dispositivo Intravaginal:</strong>{' '}
              {this.props.initialValues.implant}
            </li>
            <li>
              <strong>Estímulo Ovulatório:</strong>{' '}
              {this.props.initialValues.benzoato}
            </li>
            {!_.isEmpty(this.props.initialValues.luteolise) && (
              <li>
                <strong>Luteólise:</strong> {this.props.initialValues.luteolise}
              </li>
            )}
            {!_.isEmpty(this.props.initialValues.progesterona) && (
              <li>
                <strong>Progesterona Injetável:</strong>{' '}
                {this.props.initialValues.progesterona}
              </li>
            )}
          </ul>
        </div>

        <div className='grid-item p-12 t-6 p-display-flex d0-grid-form-top-info-right'>
          <h3>TOTAL DE MATRIZES: {this.state.count}</h3>

          <CustomSwitch
            onHandleChange={this.handleSwitchChange}
            checked={this.state.checked}
            text='Avisar sobre matrizes repetidas'
          ></CustomSwitch>
        </div>

        <div className='grid-item p-12 p-padding-vertical-1'>
          <hr />
        </div>
      </>
    )
  }

  render() {
    console.log(this.props.usoImplantes);

    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={this.renderTitle()}
          buttons={this.getButtons()}
          paths={this.getPaths()}
        />
        <MainContainer>
          <div className='grid d0-grid-form'>
            {this.renderHeader()}

            <div className='grid-item p-10 d-4'>
              <TextField
                id='codVaca'
                label='ID1/Matriz'
                inputRef={this.codVaca}
                autoFocus
                disabled={this.state.loading}
                onChange={(e) => {
                  this.textfieldDefaultOnChange(e)
                  this.setState({ enterSubmit: true })
                }}
                onKeyDown={this.onEnterPress}
                value={this.state.codVaca || null}
                error={!_.isEmpty(this.state.codVaca_error)}
                helperText={this.state.codVaca_error}
              />
            </div>

            <div className='grid-item p-2 d-1'>
              <Button
                secondary
                small
                tabIndex='-1'
                style={{
                  height: '55px',
                  width: '100%',
                }}
                onClick={async () => {
                  try {
                    const resposta = await SpeechRecognition()
                    if (resposta !== null)
                      this.setState({
                        codVaca: resposta,
                        codVaca_error:
                          _.isEmpty(resposta) &&
                          REQUIRED_FIELD_MESSAGES.DEFAULT,
                      })
                  } catch (e) {
                    console.log(e)
                  }
                }}
                label={<MicIcon />}
                containerStyle={{ textAlign: 'center' }}
              />
            </div>

            <div className='grid-item p-12 d-4'>
              <TextField
                id='codLeitorBastao'
                label='ID2/Leitor de bastão'
                inputRef={this.codLeitorBastao}
                disabled={this.state.loading}
                onChange={(e) => {
                  this.textfieldDefaultOnChange(e)
                  this.setState({ enterSubmit: true, codVaca_error: null })
                }}
                onKeyDown={this.onEnterPress}
                value={this.state.codLeitorBastao || null}
              />
            </div>

            <div className='grid-item p-10 t-4 d-2'>
              <Autocomplete
                id='ecc'
                options={this.props.ecc}
                value={this.state.ecc || null}
                onChange={this.autocompleteDefaultOnChangeSavingID('ecc')}
                onKeyDown={this.state.ecc && this.onEnterPress}
                onFocus={() => this.setState({ enterSubmit: false })}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.ecc
                )}
                useDefaultOptionSelected
                label='ECC'
                error={!_.isEmpty(this.state.ecc_error)}
                helperText={this.state.ecc_error}
              />
            </div>

            <div className='grid-item p-2 d-1'>
              <Button
                secondary
                small
                tabIndex='-1'
                style={{
                  height: '55px',
                  width: '100%',
                }}
                onClick={async () => {
                  try {
                    const answerToUser = eccSpeechRecognition(
                      await SpeechRecognition()
                    )

                    if (answerToUser !== null) {
                      this.setState({
                        ecc: answerToUser,
                      })
                    }
                  } catch (e) {
                    console.log(e)
                  }
                }}
                label={<MicIcon />}
                containerStyle={{ textAlign: 'center' }}
              />
            </div>

            <div className='grid-item p-10 t-4 d-2'>
              <TextField
                id='peso_EM'
                label='Peso Inicial'
                type='number'
                // onChange={this.textfieldDefaultOnChange}
                onChange={(e) => {
                  this.textfieldDefaultOnChange(e)
                  this.setState({ enterSubmit: true })
                }}
                onKeyDown={this.onEnterPress}
                value={this.state.peso_EM || null}
                error={!_.isEmpty(this.state.peso_EM_error)}
                helperText={this.state.peso_EM_error}
              />
            </div>

            <div className='grid-item p-2 t-2 d-1'>
              <Button
                secondary
                small
                tabIndex='-1'
                style={{
                  height: '55px',
                  width: '100%',
                }}
                onClick={async () => {
                  const resposta = await SpeechRecognition()
                  if (resposta !== null)
                    this.setState({
                      peso_EM: resposta,
                      peso_EM_error: null,
                    })
                }}
                label={<MicIcon />}
                containerStyle={{ textAlign: 'center' }}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                id='clD0'
                label='Presença de CL'
                options={this.props.clD0}
                value={this.state.clD0 || null}
                onChange={this.autocompleteDefaultOnChangeSavingID('clD0')}
                onKeyDown={this.state.clD0 && this.onEnterPress}
                onFocus={() => this.setState({ enterSubmit: false })}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.clD0
                )}
                useDefaultOptionSelected
              />
            </div>

            {this.state.focusDate ? (
              <div className='grid-item p-12 p-offset-3 t-6 d-3'>
                <DateField
                  id='dataPartoIndividual'
                  label='Data do parto individual'
                  value={this.state.dataPartoIndividual || null}
                  onChange={(e) => this.setState({ dataPartoIndividual: e })}
                  onBlur={() => {
                    if (!this.state.dataPartoIndividual) {
                      this.setState({ focusDate: false })
                    }
                  }}
                  onKeyDown={this.onEnterPress}
                  onClickCaptureButton={() =>
                    this.setState({ dataPartoIndividual: new Date() })
                  }
                  error={
                    !this.isDateNullOrValid(this.state.dataPartoIndividual)
                  }
                />
              </div>
            ) : (
              <div className='grid-item p-12 p-offset-3 t-6 d-3'>
                <DateField
                  initialValues={[
                    this.state.dataPartosInicio,
                    this.state.dataPartosFim,
                  ]}
                  label='Data do parto'
                  onFocus={() => this.setState({ focusDate: true })}
                  ranged
                />
              </div>
            )}

            <div className='grid-item p-12 t-12 d-4'>
              <TextField
                id='observacaoD0'
                label='Observação'
                onChange={(e) => {
                  this.textfieldDefaultOnChange(e)
                  this.setState({ enterSubmit: true })
                }}
                onKeyDown={this.onEnterPress}
                value={this.state.observacaoD0 || null}
                error={!_.isEmpty(this.state.observacaoD0_error)}
                helperText={this.state.observacaoD0_error}
              />
            </div>

            <div className='grid-item p-12 p-padding-vertical-1'>
              <hr />
            </div>

            <div className='grid-item p-12 p-offset-3 t-6 d-2'>
              <Autocomplete
                id='idade_Nov_Pri'
                options={this.props.idadeMatriz}
                value={this.state.idade_Nov_Pri || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'idade_Nov_Pri'
                )}
                onKeyDown={this.state.idade_Nov_Pri && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.idadeMatriz
                )}
                onFocus={() => this.setState({ enterSubmit: false })}
                useDefaultOptionSelected
                label='Idade (Meses)'
                error={!_.isEmpty(this.state.idade_Nov_Pri_error)}
                helperText={this.state.idade_Nov_Pri_error}
              />
            </div>
            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                id='racaMatriz'
                options={this.props.racaMatriz}
                value={this.state.racaMatriz || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'racaMatriz'
                )}
                onKeyDown={this.state.racaMatriz && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                  this.props.racaMatriz
                )}
                onFocus={() => this.setState({ enterSubmit: false })}
                useDefaultOptionSelected
                label='Raça da Matriz'
                error={!_.isEmpty(this.state.racaMatriz_error)}
                helperText={this.state.racaMatriz_error}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                id='ordemMatriz'
                options={this.props.ordemMatriz}
                value={this.state.ordemMatriz || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'ordemMatriz'
                )}
                onKeyDown={this.state.ordemMatriz && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                  this.props.ordemMatriz
                )}
                onFocus={() => this.setState({ enterSubmit: false })}
                useDefaultOptionSelected
                label='Ordem da Matriz'
                error={!_.isEmpty(this.state.ordemMatriz_error)}
                helperText={this.state.ordemMatriz_error}
              />
            </div>

            <div className='grid-item p-12 p-offset-3 t-6 d-2'>
              <Autocomplete
                id='dispositivoIntravaginal'
                options={this.props.implantes}
                value={this.state.dispositivoIntravaginal || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'dispositivoIntravaginal'
                )}
                onKeyDown={
                  this.state.dispositivoIntravaginal && this.onEnterPress
                }
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.implantes
                )}
                onFocus={() => this.setState({ enterSubmit: false })}
                useDefaultOptionSelected
                label='Dispositivo Intravaginal'
                error={!_.isEmpty(this.state.dispositivoIntravaginal_error)}
                helperText={this.state.dispositivoIntravaginal_error}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                id='numero_Uso_Disp'
                options={this.props.usoImplantes}
                value={this.state.numero_Uso_Disp || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'numero_Uso_Disp'
                )}
                onKeyDown={this.state.numero_Uso_Disp && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.usoImplantes
                )}
                onFocus={() => this.setState({ enterSubmit: false })}
                useDefaultOptionSelected
                label='Uso do Dispositivo'
                error={!_.isEmpty(this.state.numero_Uso_Disp_error)}
                helperText={this.state.numero_Uso_Disp_error}
              />
            </div>

            <div className='grid-item p-12 t-6 d-2'>
              <Autocomplete
                id='responsavelId'
                options={this.props.usuarios}
                value={this.state.responsavelId || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'responsavelId'
                )}
                onKeyDown={this.state.responsavelId && this.onEnterPress}
                getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                  this.props.usuarios,
                  'nomeCompleto'
                )}
                onFocus={() => this.setState({ enterSubmit: false })}
                useDefaultOptionSelected
                label='Responsável'
                error={!_.isEmpty(this.state.responsavelId_error)}
                helperText={this.state.responsavelId_error}
              />
            </div>

            <div className='grid-item p-12 p-display-flex p-justify-space-between'>
              {this.state.toEditCow === true ? (
                <Button
                  color={COLORS.GREY}
                  label='Cancelar'
                  onClick={() => {
                    this.onClean()
                    this.setState({
                      toEditCow: false,
                      focusDate: false,
                      dataPartoIndividual: null,
                    })
                  }}
                />
              ) : (
                <Button
                  color={COLORS.GREY}
                  label='Limpar'
                  onClick={this.onClean}
                  startIcon={<DeleteIcon />}
                />
              )}

              <Button
                label='Salvar'
                disabled={this.state.loading || !!this.state.codVaca_error || this.props.user.roles[0].name === 'Cliente (somente visualização)'}
                onClick={!this.state.toEditCow ? this.handleSave : this.editCow}
                startIcon={<SaveIcon />}
                containerStyle={{ textAlign: 'right' }}
              />
            </div>
          </div>

          <Prompt
            visible={verify()}
            title={duplicatePage.title}
            message={duplicatePage.text}
            buttons={[{ autoFocus: false }]}
          />

          <Prompt
            visible={this.state.showingFinalizeConfirmation}
            title={
              this.state.count > 0 ? 'Deseja Finalizar?' : 'Erro ao finalizar'
            }
            message={
              this.state.count > 0
                ? 'Uma vez finalizado os dados não salvos serão perdidos.'
                : 'Não há matrizes cadastradas neste lote.'
            }
            buttons={
              this.state.count > 0
                ? [
                  {
                    label: 'Não',
                    onClick: () =>
                      this.setState({
                        showingFinalizeConfirmation: false,
                        confirmedBackupDownload: true,
                      }),
                  },
                  {
                    label: 'Sim',
                    onClick: this.state.confirmedBackupDownload
                      ? () => this.finalize(true)
                      : () =>
                        this.setState({
                          showingFinalizeConfirmation: false,
                          showingNoBackupConfirmation: true,
                        }),
                  },
                ]
                : [
                  {
                    label: 'Ok',
                    onClick: () => {
                      this.setState({ showingFinalizeConfirmation: false })
                    },
                  },
                ]
            }
          />
          <Prompt
            visible={this.state.searchError}
            title={'Matriz já cadastrada em outro(s) lotes'}
            message={
              this.state.searchError
                ? this.state.searchError
                : 'Erro desconhecido'
            }
            secondaryMessage={
              'Para não ver este aviso, desmarque a opção "Avisar sobre matrizes repetidas".'
            }
            buttons={[
              {
                label: 'Cancelar',
                autoFocus: false,
                onClick: () => this.setState({ searchError: false }),
              },
              {
                label: 'Salvar',
                onClick: () => {
                  this.onSave()
                },
              },
            ]}
          />

          <Prompt
            visible={this.state.showingNoBackupConfirmation}
            title='Atenção!'
            message='O comprovante de manejo pode ser necessário durante um eventual cenário de perda de dados. Não baixar o comprovante poderá dificultar a recuperação das informações desse manejo. Deseja continuar mesmo assim?'
            buttons={[
              {
                label: 'Cancelar',
                onClick: () =>
                  this.setState({
                    showingNoBackupConfirmation: false,
                    showingFinalizeConfirmation: true,
                  }),
              },
              {
                label: 'Sim',
                onClick: () => this.finalize(false),
              },
            ]}
          />

          {!this.state.toEditCow && (
            <>
              <Divider
                style={{
                  width: '100%',
                  height: '2px',
                  marginBottom: '-16px',
                }}
              />
              <MainContainer
                containerTitle={`Matrizes`}
                searchLabel='Buscar'
                handleSearch={this.handleSearch}
                hasSearchWithFilterButtton
                handleButtonFilter={this.handleToggleFilters}
                skipVersion={true}
              >
                {this.state.filtersVisible && (
                  <div className='grid grid-batch-form'>
                    <div className='grid-item p-12 header-info-left'>
                      <p className='p-font-weight-700'>Outros Filtros</p>
                    </div>
                    <div className="grid-item p-12 t-6 d-4">
                      <Autocomplete
                        id='racaMatrizFilter'
                        options={this.props.racaMatriz}
                        value={this.state.racaMatrizFilter || null}
                        getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                          this.props.racaMatriz,
                          'descricao'
                        )}
                        label='Raça da Matriz'
                        onChange={(e, value) => {
                          this.autocompleteDefaultOnChange('racaMatrizFilter')
                          this.handleFilterMatriz(value)
                        }}
                      />
                    </div>
                    <div className="grid-item p-12 t-6 d-4">
                      <Autocomplete
                        id='ordemMatrizFilter'
                        options={this.props.ordemMatriz}
                        value={this.state.ordemMatrizFilter || null}
                        onChange={(e, value) => {
                          this.autocompleteDefaultOnChange('ordemMatrizFilter')
                          this.handleFilterOrdemMatriz(value)
                        }}
                        getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                          this.props.ordemMatriz,
                          'descricao'
                        )}
                        label='Ordem da Matriz'
                      />
                    </div>
                    <div className="grid-item p-12 t-6 d-4">
                      <Autocomplete
                        id='numUsoDispFilter'
                        options={this.props.usoImplantes}
                        value={this.state.numUsoDispFilter || null}
                        onChange={(e, value) => {
                          this.autocompleteDefaultOnChange('numUsoDispFilter')
                          this.handleFilterUsoMatriz(value)
                        }}
                        getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
                          this.props.usoImplantes,
                          'valor'
                        )}
                        label='Uso do Dispositivo'
                      />
                    </div>
                    <div className="grid-item p-12 p-display-flex p-justify-space-between">
                      <Button
                        color={COLORS.GREY}
                        label={'Limpar'}
                        type='button'
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          this.setState({ ordemMatrizFilter: null })
                          this.setState({ numUsoDispFilter: null })
                          this.setState({ racaMatriz: null })
                          this.setState({ selectedCows: this.state.allCows })
                        }}
                      />
                    </div>
                  </div>
                )}
                <ResponsiveTable
                  columns={this.getTableColumnData()}
                  data={this.state.selectedCows}
                  noDataMessage='Ainda não existe nenhuma vaca neste lote!'
                  toDataExport={this.props}
                  className='table-d0'
                  onSort={(sortedData) => this.setState({ selectedCows: sortedData })}
                />
                <Prompt
                  visible={this.state.deleteConfirmationOpened}
                  title='Confirmar Exclusão'
                  message={`Deseja realmente excluir esta matriz (${this.state.selectedCowCode})?`}
                  buttons={[
                    {
                      label: 'Não',
                      onClick: () =>
                        this.setState({ deleteConfirmationOpened: false }),
                    },
                    {
                      label: 'Sim',
                      onClick: () => this.deleteCow(this.state.selectedCow),
                    },
                  ]}
                />
              </MainContainer>
            </>
          )}
        </MainContainer>
      </Container>
    )
  }
}

async function parseD0Info(protocol, d0, tipoManejo) {
  let ret = {
    implant: '',
    benzoato: '',
    luteolise: '',
    progesterona: '',
  }
  if (protocol && protocol.managementProtocols) {
    const d0s = _.filter(
      protocol.managementProtocols,
      (managementProtocol) => managementProtocol.day === d0.id
    )
    const benzoato = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Estímulo Ovulatório'
    )
    const luteolise = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Luteólise'
    )
    const progesterona = _.find(
      tipoManejo,
      (manejo) => manejo.valor === 'Progesterona Injetável'
    )

    _.each(d0s, async (d0) => {
      if (d0.implantId) {
        ret.implant = (
          await utils.getElement(AVAILABLE_ENTITIES.DOMAIN_VALUES, d0.implantId)
        ).valor
      }
      const hormone = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        d0.hormoneTypeId
      )
      const dosage = await utils.getElement(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        d0.hormoneDosageId
      )

      const dosageValue = dosage ? dosage.valor : d0.hormoneDosageValue

      switch (d0.managementId) {
        case benzoato.id:
          ret.benzoato = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        case luteolise.id:
          ret.luteolise = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        case progesterona.id:
          ret.progesterona = `${hormone.valor}${_.isEmpty(dosageValue) ? '' : ` - ${dosageValue} mL`
            }`
          break
        default:
      }
    })
  }
  return ret
}

const propsFromDatabase = async (props) => {
  const batchIds = props.match.params.id.split(';')

  const batches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'id',
    Q.oneOf(batchIds)
  )
  const batch = await utils.getElement(
    AVAILABLE_ENTITIES.BATCHES,
    props.match.params.id
  )

  const corral = await utils.getElement(
    AVAILABLE_ENTITIES.CORRALS,
    batch.retiroId
  )

  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    corral.fazendaId
  )

  const usuarios = await utils.getWithRelation(
    AVAILABLE_ENTITIES.USERS,
    'farms__rel__users',
    'farm_id',
    corral.fazendaId
  )

  const dias = await utils.getDomainValuesBy('Dias')
  const protocol =
    batch.protocoloId &&
    (await utils.getElement(AVAILABLE_ENTITIES.PROTOCOLS, batch.protocoloId))
  const d0 = _.find(dias, { valor: 'D0' })
  const tipoManejo = await utils.getDomainValuesBy('Tipo de Manejo')
  const d0Info = await parseD0Info(protocol, d0, tipoManejo)

  return {
    requiredFields: [
      'codVaca',
      'responsavelId',
      'dispositivoIntravaginal',
      'numero_Uso_Disp',
      'racaMatriz',
      'ordemMatriz',
    ],
    batches,
    batch,
    corral,
    farm,
    usuarios: usuarios ? usuarios : [],
    protocol,
    vacas: await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      props.match.params.id
    ),
    ecc: _.sortBy(await utils.getDomainValuesBy('ECC'), [
      (ecc) => parseFloat(ecc.valor),
    ]),
    ordemMatriz: await utils.getDomainValuesBy('Ordem da Matriz Predominante'),
    racaMatriz: await utils.getDomainValuesBy('Raça da Matriz predominante'),
    racaBezerro: await utils.getDomainValuesBy('Raça do bezerro'),
    sexoBezerro: await utils.getDomainValuesBy('Sexo do Bezerro'),
    idadeMatriz: _.sortBy(await utils.getDomainValuesBy('Idade da Matriz'), [
      (idade) => parseInt(idade.valor.split(' ')[0]),
    ]),
    implantes: _.sortBy(await utils.getDomainValuesBy('Dispositivo'), [
      (implant) => !implant.valor.toLowerCase().startsWith('zoetis'),
      (implant) => implant.valor.toLowerCase(),
    ]),
    usoImplantes: _.sortBy(
      await utils.getDomainValuesBy('Tipo de Implante Predominante'),
      ['valor']
    ),
    clD0: _.sortBy(await utils.getDomainValuesBy('CL'), [
      (cl) => !cl.valor.toLowerCase().startsWith('sim'),
      (cl) => !cl.valor.toLowerCase().startsWith('não'),
      (cl) => cl.valor.toLowerCase(),
    ]),
    initialValues: {
      ...d0Info,
      ordemMatriz: batch.ordemMatriz,
      racaMatriz: batch.racaMatriz,
      idade_Nov_Pri: batch.idadeMatriz,
      dispositivoIntravaginal: batch.dispositivo,
      dataPartosInicio: batch.dataPartosInicio,
      dataPartosFim: batch.dataPartosFim,
      numero_Uso_Disp: batch.numeroUsoDispositivo,
      responsavelId: batch.usuarioId,
    },
    d0,
    user: await utils.getElement(
      AVAILABLE_ENTITIES.USERS,
      (
        await getLoggedUser()
      ).userId
    ),
  }
}

export default utils.withPropsFromDatabase(propsFromDatabase, withRouter(D0))
